import View from "./View";
import imagePlaceholder from "url:../../images/no_image.png";

class birdInfoView extends View {
  _parentElement = document.querySelector(".bird__information__container");
  image = document.querySelector(".bird__information__image");
  spinner = document.querySelector(".spinner__overlay");
  overlayBlur = document.querySelector(".overlay-blur");

  constructor() {
    super();
    this._addHandlerCloseWindow();
  }

  showBirdInfo() {
    this._parentElement.classList.remove("hidden");
    this.overlayBlur.classList.remove("hidden");
  }

  hideBirdInfo() {
    this._parentElement.classList.add("hidden");
    this.overlayBlur.classList.add("hidden");
  }

  _addHandlerCloseWindow() {
    this._parentElement
      .querySelector(".close-info__btn")
      .addEventListener("click", this.hideBirdInfo.bind(this));
  }

  setImageSource(src, disabled) {
    this.spinner.classList.remove("hidden");
    this.image.classList.add("hidden");

    if (disabled === "on") {
      this.image.src = imagePlaceholder;
      return;
    }
    this.image.src = src;

    this.renderImage();
  }

  renderImage = function () {
    this.image.addEventListener("load", () => {
      document
        .querySelector(".bird__information__image")
        .classList.remove("hidden");
      document.querySelector(".spinner__overlay").classList.add("hidden");
    });
  };
}

export default new birdInfoView();
