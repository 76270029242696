import View from "./View";

class menuView extends View {
  _parentElement = document.querySelector(".nav");
  _container = document.querySelector(".birds__container");
  btnMenu = document.querySelector(".nav__btn");
  menu = document.querySelector(".nav__menu");
  list = document.querySelector(".nav__list");
  title = document.querySelector(".section__title");

  orientationSettings() {
    const orientation = this.getWindowOrientation();
    if (orientation === "landscape-primary") this.title.classList.add("hidden");
    if (orientation !== "landscape-primary")
      this.title.classList.remove("hidden");
  }

  // Handler for hash-change and page load for nav items
  addHandlerNavigation(handler) {
    ["hashchange", "load"].forEach((ev) =>
      window.addEventListener(ev, handler)
    );
  }

  // animate nav item
  animateNavItem(navItem) {
    const item = document.getElementById(navItem);

    if (!item) return;

    item.style.animation = "menuSelect 0.25s 1";
    setTimeout(() => {
      item.style.animation = "";
    }, 250);
  }

  clearStyleAnimation() {
    this._parentElement.style.animation = "none";
  }

  addHandlerMenuButton(handler) {
    this._parentElement.addEventListener("click", function (e) {
      const btn = e.target.closest(".nav__btn__container");
      if (!btn) return;
      handler();
    });
  }

  navClose() {
    this.btnMenu.classList.remove("nav__btn__up");
    this.btnMenu.classList.add("nav__btn__down");
    this.menu.style.height = 0;
  }

  navOpen() {
    this.btnMenu.classList.add("nav__btn__up");
    this.btnMenu.classList.remove("nav__btn__down");
    this.menu.style.height = `${this.list.offsetHeight}px`;
  }

  renderRotationBtn(state) {
    if (state === "open") this.navClose();
    if (state === "closed") this.navOpen();
  }

  renderRotation(state) {
    if (state === "open") {
      const orientation = this.getWindowOrientation();
      if (orientation !== "landscape-primary") this.navClose();
    }
    if (state === "closed") this.navOpen();
  }
}

export default new menuView();
