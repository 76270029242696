export const VERSION = "v1.0";
export const API_TIMER_SECONDS = 1100;
export const API_URL = "https://xeno-canto.org/api/2/recordings";
export const WIKI_API =
  "https://en.wikipedia.org/w/api.php?origin=*&action=query&prop=pageimages|pageterms&piprop=original|thumbnail&pithumbsize=220&redirects&format=json&titles=";

export const WIKI_API_META =
  "https://en.wikipedia.org/w/api.php?origin=*&action=query&prop=imageinfo&iiprop=extmetadata&titles=File%3a";
export const EBIRD_API = "https://api.ebird.org/v2/ref/hotspot/";

export const THEMES = {
  spring: {
    highlight: "hsl(300, 80%, 70%)",
    bird: "hsl(195, 80%, 85%)",
    background: "hsl(120, 80%, 95%)",
    title: "hsl(300, 100%, 95%)",
  },
  summer: {
    highlight: "hsl(195, 100%, 80%)",
    bird: "#FC865A",
    background: "#F9DF56",
    title: "hsl(195, 100%, 80%)",
  },
  autumn: {
    highlight: "#606c38",
    bird: "#f2a65a",
    background: "#eec170",
    title: "#bc6c25",
  },
  // autumn: {
  //   highlight: "#c18c5d",
  //   bird: "#ce796b",
  //   background: "#ecc8af",
  //   title: "#606c38",
  // },
  winter: {
    highlight: "hsl(176, 85%, 50%)",
    bird: "hsl(176, 75%, 55%)",
    background: "hsl(176, 50%, 77%)",
    title: "hsla(176, 100%, 50%, 0.25)",
  },
};

export const ALPHABETISE_IGNORE = [
  "Abyssinian",
  "African",
  "Amazonian",
  "American",
  "Andaman",
  "Andean",
  "Angola",
  "Arabian",
  "Asian",
  "Atlantic",
  "Australasian",
  "Australian",
  "Bermuda",
  "Bolivian",
  "Bornean",
  "Brazilian",
  "Burmese",
  "California",
  "Cameroon",
  "Chinese",
  "Common",
  "Congo",
  "Cuban",
  "Eastern",
  "Ecuadorian",
  "Ethiopian",
  "Eurasian",
  "European",
  "Fiji",
  "Galapagos",
  "Grey",
  "Guianan",
  "Hawaii",
  "Hawaiian",
  "Himalayan",
  "Hispaniolan",
  "Indian",
  "Indochinese",
  "Jamaican",
  "Japanese",
  "Javan",
  "Madagascar",
  "Malayan",
  "Malaysian",
  "Mascarene",
  "Mexican",
  "Micronesian",
  "Moluccan",
  "North",
  "Northern",
  "Oriental",
  "Pacific",
  "Papuan",
  "Patagonian",
  "Philippine",
  "Polynesian",
  "Puerto",
  "Reunion",
  "Rio",
  "Samoan",
  "Senegal",
  "Seychelles",
  "Siberian",
  "Somali",
  "South",
  "Southern",
  "Sri",
  "Sumatran",
  "Swamp",
  "Taiwan",
  "Tibetan",
  "Tropical",
  "Venezuelan",
  "West",
  "Western",
];

export const REGIONS = [
  { code: "ALL", name: "Worldwide" },
  { code: "NA", name: "North America" },
  { code: "MA", name: "Central America" },
  { code: "SA", name: "South America" },
  { code: "AF", name: "Africa" },
  { code: "PAL", name: "Europe and Asia" },
  { code: "OR", name: "Oriental Region" },
  { code: "AU", name: "Australasia" },
  { code: "AN", name: "Antarctica" },
  { code: "AO", name: "Atlantic Ocean" },
  { code: "PO", name: "Pacific Ocean" },
  { code: "IO", name: "India Ocean" },
  { code: "TrO", name: "Troplical Ocean" },
  { code: "TO", name: "Temperate Ocean" },
  { code: "NA", name: "Northern Ocean" },
  { code: "SO", name: "Southern Ocean" },
];

export const XENO_REGIONS = [
  { region: "Worldwide", xenoRegion: "" },
  { region: "Europe and Asia", xenoRegion: "europe" },
  { region: "Europe", xenoRegion: "europe" },
  { region: "North America", xenoRegion: "america" },
  { region: "Northern America", xenoRegion: "america" },
  { region: "Central America", xenoRegion: "america" },
  { region: "South America", xenoRegion: "america" },
  { region: "Asia", xenoRegion: "asia" },
  { region: "Oriental Region", xenoRegion: "asia" },
  { region: "Australasia", xenoRegion: "australia" },
  { region: "Africa", xenoRegion: "africa" },
  { region: "Antarctica", xenoRegion: "" },
];

export const COUNTRIES = [
  { region: "South America", code2: "AR", code3: "ARG", name: "Argentina" },
  { region: "Australasia", code2: "AU", code3: "AUS", name: "Australia" },
  { region: "Europe and Asia", code2: "AT", code3: "AUT", name: "Austria" },
  { region: "Europe and Asia", code2: "BE", code3: "BEL", name: "Belgium" },
  { region: "South America", code2: "BR", code3: "BRA", name: "Brazil" },
  { region: "North America", code2: "CA", code3: "CAN", name: "Canada" },
  { region: "Europe and Asia", code2: "CN", code3: "CHN", name: "China" },
  { region: "South America", code2: "CO", code3: "COL", name: "Colombia" },
  { region: "Europe and Asia", code2: "HR", code3: "HRV", name: "Croatia" },
  {
    region: "Europe and Asia",
    code2: "CZ",
    code3: "CZE",
    name: "Czech Republic",
  },
  { region: "Europe and Asia", code2: "DK", code3: "DNK", name: "Denmark" },
  { region: "South America", code2: "EC", code3: "ECU", name: "Ecuador" },
  { region: "Europe and Asia", code2: "EE", code3: "EST", name: "Estonia" },
  { region: "Europe and Asia", code2: "FI", code3: "FIN", name: "Finland" },
  { region: "Europe and Asia", code2: "FR", code3: "FRA", name: "France" },
  { region: "Europe and Asia", code2: "DE", code3: "DEU", name: "Germany" },
  { region: "Europe and Asia", code2: "GR", code3: "GRC", name: "Greece" },
  { region: "Europe and Asia", code2: "HU", code3: "HUN", name: "Hungary" },
  { region: "Europe and Asia", code2: "IS", code3: "ISL", name: "Iceland" },
  { region: "Oriental Region", code2: "IN", code3: "IND", name: "India" },
  { region: "Oriental Region", code2: "ID", code3: "IDN", name: "Indonesia" },
  { region: "Europe and Asia", code2: "IE", code3: "IRL", name: "Ireland" },
  { region: "Europe and Asia", code2: "IT", code3: "ITA", name: "Italy" },
  { region: "Europe and Asia", code2: "JP", code3: "JPN", name: "Japan" },
  { region: "Africa", code2: "KE", code3: "KEN", name: "Kenya" },
  { region: "Africa", code2: "MG", code3: "MDG", name: "Madagascar" },
  { region: "Oriental Region", code2: "MY", code3: "MYS", name: "Malaysia" },
  { region: "Central America", code2: "MX", code3: "MEX", name: "Mexico" },
  { region: "Europe and Asia", code2: "NL", code3: "NLD", name: "Netherlands" },
  { region: "Australasia", code2: "NZ", code3: "NZL", name: "New Zealand" },
  { region: "Europe and Asia", code2: "NO", code3: "NOR", name: "Norway" },
  {
    region: "Australasia",
    code2: "PG",
    code3: "PNG",
    name: "Papua New Guinea",
  },
  { region: "South America", code2: "PE", code3: "PER", name: "Peru" },
  { region: "Europe and Asia", code2: "PL", code3: "POL", name: "Poland" },
  { region: "Europe and Asia", code2: "PT", code3: "PRT", name: "Portugal" },
  { region: "Europe and Asia", code2: "RU", code3: "RUS", name: "Russia" },
  { region: "Australasia", code2: "SB", code3: "SLB", name: "Solomon Islands" },
  { region: "Africa", code2: "ZA", code3: "ZAF", name: "South Africa" },
  { region: "Europe and Asia", code2: "ES", code3: "ESP", name: "Spain" },
  { region: "Europe and Asia", code2: "SE", code3: "SWE", name: "Sweden" },
  { region: "Europe and Asia", code2: "CH", code3: "CHE", name: "Switzerland" },
  { region: "Australasia", code2: "TZ", code3: "TZA", name: "Tanzania" },
  { region: "Oriental Region", code2: "TH", code3: "THA", name: "Thailand" },
  { region: "Europe and Asia", code2: "TR", code3: "TUR", name: "Turkey" },
  { region: "Africa", code2: "UG", code3: "UGA", name: "Uganda" },
  { region: "Europe and Asia", code2: "UA", code3: "UKR", name: "Ukraine" },
  {
    region: "Europe and Asia",
    code2: "GB",
    code3: "GBR",
    name: "United Kingdom",
  },
  { region: "North America", code2: "US", code3: "USA", name: "United States" },
  { region: "Africa", code2: "ZM", code3: "ZMB", name: "Zambia" },
];
