import View from "./View";

class audioScrollerView extends View {
  _parentElement = document.querySelector(".audio__track__details");
  _errorMessage = "";

  setAnimationVariables() {
    const scroller = document.querySelector(
      ".audio__track__details__scroller__text"
    );

    const scrollerWidth = scroller.scrollWidth;
    const scrollerWidthOffset = scroller.offsetWidth;

    const translateXperc = `${-(scrollerWidth - scrollerWidthOffset)}px`;

    document.documentElement.style.setProperty(
      "--scroll-animation-translateX",
      translateXperc
    );
  }

  _generateMarkup() {
    return `
        <p class="audio__track__details__bird">🐦 <span class="track__details__highlight">${this.data.en}</span> &nbsp</p>
        <div class="audio__track__details__scroller"><p class="audio__track__details__scroller__text">Country recorded: <span class="track__details__highlight">${this.data.country}</span> - Location: ${this.data.location ? this.data.location : "?"} 👤 Recorded by: ${this.data.recordist} - <a class="audio__track__details-link track__details__highlight" href="https://${this.data.url}" target=”_blank”>LINK TO SOURCE</a></p></div>
      `;
  }
}

export default new audioScrollerView();
