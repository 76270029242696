import View from "./View";

class audioView extends View {
  _parentElement = document.querySelector(".audio__container");
  _audioSelectors = document.querySelector(".audio__selector__container");
  overlay = document.querySelector(".audio__container__overlay");
  soundwave = document.getElementById("soundwave");
  wave = document.querySelectorAll(".wave");
  selectBtn = document.querySelector(".btn__change__track");
  infoBtn = document.querySelector(".info__icon__audio");
  likeBtn = document.querySelector(".heart__icon");
  likeBtnSvg = document.querySelector(".heart__icon__fill");

  addHandlerSelectBtn(handler) {
    this.selectBtn.addEventListener("click", handler);
  }

  addHandlerinfoBtn(handler) {
    this.infoBtn.addEventListener("click", handler);
  }

  addHandlerLikeBtn(handler) {
    this.likeBtn.addEventListener("click", handler);
  }

  likeBtnColour(colour) {
    this.likeBtnSvg.style.fill = colour;
  }

  hideOverlay() {
    this.overlay.classList.add("hidden");
  }

  showOverlay() {
    this.overlay.classList.remove("hidden");
    const parentHeight = this.getContainerHeight();
    console.log(`${parentHeight}px`);
    this.overlay.style.height = `${parentHeight}px`;
  }

  getAudioContainerHeight() {
    return this._parentElement.offsetHeight;
  }

  hideControls() {
    this._parentElement
      .querySelector(".audio__selector__container")
      .classList.remove("hidden");
    document
      .querySelector(".loading__colour__container")
      .classList.remove("hidden");
    this._parentElement
      .querySelector(".audio__playback__container")
      .classList.add("hidden");
    this._parentElement
      .querySelector(".audio__selectors")
      .classList.add("hidden");
  }

  showControls() {
    this._parentElement
      .querySelector(".audio__selector__container")
      .classList.remove("hidden");
    document
      .querySelector(".loading__colour__container")
      .classList.add("hidden");
    this._parentElement
      .querySelector(".audio__playback__container")
      .classList.remove("hidden");
    this._parentElement
      .querySelector(".audio__selectors")
      .classList.remove("hidden");

    this.hideSelectButton();
    this.showSoundwave();
  }

  hideSelectorContainer() {
    this._audioSelectors.classList.add("hidden");
  }

  showSelectorContainer() {
    this._audioSelectors.classList.remove("hidden");
  }

  hideSoundwave() {
    document.getElementById("soundwave").classList.add("hidden");
    document.getElementById("soundwave").style.height = "0";
  }

  showSoundwave() {
    document.getElementById("soundwave").classList.remove("hidden");
    document.getElementById("soundwave").style.height = "36px";
  }

  pauseSoundwave() {
    this.wave.forEach((wave) => wave.classList.add("stop_animation"));
  }

  playSoundwave() {
    this.wave.forEach((wave) => wave.classList.remove("stop_animation"));
  }

  showSelectButton() {
    document.querySelector(".btn__change__track").classList.remove("hidden");
  }

  hideSelectButton() {
    document.querySelector(".btn__change__track").classList.add("hidden");
  }
}

export default new audioView();
