import View from "./View";

class audioControlsView extends View {
  _parentElement = document.querySelector(".audio__playback__container");
  _errorMessage = "Sorry! No recordings available";

  pauseAudio() {
    const player = document.querySelector(".audio__controls");
    if (player) player.pause();
  }

  addHandlerAudioEnded(handler) {
    const player = document.querySelector(".audio__controls");
    ["ended", "pause"].forEach((event) => {
      player.addEventListener(event, function () {
        handler();
      });
    });
  }

  addHandlerAudioPlay(handler) {
    const player = document.querySelector(".audio__controls");
    player.addEventListener("play", function () {
      handler();
    });
  }

  _generateMarkup(data) {
    return `
            <audio class="audio__controls" controls autoplay>
                <source
                src="${data.file}"
                type="audio/wav"
                />
            </audio>
      `;
  }
}

export default new audioControlsView();
