import "regenerator-runtime/runtime";

import {
  API_URL,
  WIKI_API,
  WIKI_API_META,
  API_TIMER_SECONDS,
  ALPHABETISE_IGNORE,
  REGIONS,
  COUNTRIES,
  XENO_REGIONS,
  VERSION,
} from "./config";

import { COMMON_BIRDS } from "./common";
import { getJSON } from "./helpers";

///////////////////////////////////////////////////////////////
// STATE OBJECTS
///////////////////////////////////////////////////////////////

export const state = {
  birds: [],
  favourites: [],
  likedTracks: [],

  menu: "closed",
  activeMenu: "",

  search: {
    regionCode: "ALL",
    region: "Worldwide",
    countryCode2: "",
    countryCode3: "",
    country: "Worldwide",
    results: [],
    loadedCountry: "",
    loadedRegion: "",
  },

  activeBirdId: "",
  activeAudioTypeIndex: 0,
  activeAudioTrackIndex: 0,
  selectAudioTypeIndex: 0,
  selectAudioTrackIndex: 0,
  availableAudioTypes: [],
  availableAudioTracks: 0,

  loadedRecordings: {
    call: [],
    song: [],
    alarm: [],
  },

  settings: {
    settingDarkMode: "off",
    settingDisableImages: "off",
    likedAudio: "off",
    // settingAccessibilityColours: "off",
    theme: "",
    themeRangeColour: 195,
    themeRangeContrast: 80,
    themeRangeBrightness: 95,
  },

  xenoLoading: false,
  xenoLoaded: false,
  xenoApiDisabled: false,

  version: "",
};

const createBirdObject = function (bird) {
  return {
    name: bird.name,
    scientific: bird.scientific,
    id: bird.name.toLowerCase().replaceAll(" ", "_"),
    location: {
      commonCountryNames: bird.commonCountryNames,
      commonRegionCodes:
        bird.commonRegionCodes.length > 0
          ? bird.commonRegionCodes.map(
              (code) => REGIONS.filter((reg) => reg.code === code)[0].name
            )
          : bird.commonCountryNames
              .map(
                (name) =>
                  COUNTRIES.filter((country) => country.name === name)[0].region
              )
              .filter((val, i, arr) => arr.indexOf(val) === i),
    },
    recordings: {
      loaded: false,
      loadedCountry: "",
      loadedRegion: "",
      loadedArea: "",
      loadedAreas: [],
      call: [],
      song: [],
      alarm: [],
    },
    favourite: false,
    image: {
      pageid: "",
      title: "",
      src: "",
      thumb: "",
      filename: "",
      attribution: {
        attribution: false,
        artist: "",
        credit: "",
        license: "",
        licenseUrl: "",
      },
    },
  };
};

const createRecordingObject = function (data) {
  const recording = data;
  return {
    id: recording.id,
    en: recording.en,
    file: recording.file,
    recordist: recording.rec,
    area: getRegion(recording.cnt),
    country: recording.cnt,
    location: recording.loc,
    also: recording.also,
    type: recording.type,
    date: recording.date,
    url: recording.url,
    active: false,
    liked: state.likedTracks.includes(recording.id) ? true : false,
  };
};

const createAttributionObject = function (data) {
  return {
    attribution: data.AttributionRequired.value,
    artist: data.Artist ? data.Artist.value : "",
    credit: data.Credit ? data.Credit.value : "",
    license: data.LicenseShortName ? data.LicenseShortName.value : "",
    licenseUrl: data.LicenseUrl ? data.LicenseUrl.value : "",
  };
};

//////////////////////////////////////////////////////
// SEARCH
//////////////////////////////////////////////////////

//
const getRegion = function (country) {
  let region;
  const countryObject = COUNTRIES.filter((cnt) => cnt.name === country)[0];
  if (countryObject) region = countryObject.region;
  return region;
};

// Filter Search Selection
export const searchFilter = function () {
  // console.log(state.search);

  let countryName, countryResults, regionResults, results;
  const regionCode = state.search.regionCode;
  const countryCode3 = state.search.countryCode3;

  // console.log(state.search);
  // console.log(countryNameFilter.length);

  if (countryCode3) {
    countryName = COUNTRIES.filter(
      (country) => country.code3 === countryCode3
    )[0].name;

    countryResults = COMMON_BIRDS.filter((bird) =>
      bird.commonCountryNames.includes(countryName)
    );
    results = countryResults;
  }

  if (regionCode && regionCode !== "ALL") {
    regionResults = COMMON_BIRDS.filter((bird) =>
      bird.commonRegionCodes.includes(regionCode)
    );
    results = regionResults;
  }

  if (regionCode === "ALL") {
    regionResults = COMMON_BIRDS.filter((bird) => bird.commonAll);
    results = regionResults;
  }

  // console.log(results);

  state.search.results = results;
  // return results;
};

//////////////////////////////////////////////////////
// Return filtered bird objects
//
export const getFilteredBirdObjects = function () {
  // console.log(state.search.results);
  const birds = state.birds.filter((bird) =>
    state.search.results.map((el) => el.name).includes(bird.name)
  );

  // console.log(birds);
  return birds;
};

//////////////////////////////////////////////////////
// STATE Functions
//////////////////////////////////////////////////////

// Set search filter
export const setSearchFilter = function (value) {
  let countryName = "Various";
  let regionCode, regionName, countryCode2, countryCode3;

  const countryNameFilter = COUNTRIES.filter(
    (country) => country.code3 === value
  );

  if (countryNameFilter.length === 1) {
    countryName = countryNameFilter[0].name;
    countryCode2 = countryNameFilter[0].code2;
    countryCode3 = value;
    regionName = countryNameFilter[0].region;
  }

  if (countryNameFilter.length === 0) {
    regionCode = value;
    regionName = REGIONS.filter((region) => region.code === value)[0].name;
  }

  state.search.regionCode = regionCode;
  state.search.region = regionName;
  state.search.countryCode2 = countryCode2;
  state.search.countryCode3 = countryCode3;
  state.search.country = countryName;
};

// Update the active menu
export const updateActiveMenu = function (menu) {
  state.activeMenu = menu;
};

// Set search param sowlrdwide
export const setSearchParamsWorldwide = function () {
  state.search.regionCode = "ALL";
  state.search.region = "Worldwide";
  state.search.country = "Worldwide";
};

// toggle the menu state
export const toggleMenuState = function (orientation = "") {
  // console.log(`pre: ${state.menu}`);
  if (orientation === "landscape-primary") {
    state.menu = "open";
    // console.log(`post: ${state.menu}`);
    return;
  }
  state.menu === "closed" ? (state.menu = "open") : (state.menu = "closed");
  // console.log(`pre: ${state.menu}`);
};

// create an object in the state for all birds listed in config
export const createAllBirdObjects = function () {
  // remove any birds no longer in the list
  removeBird();

  state.search.results.forEach(function (bird) {
    if (
      state.birds.filter((el) => el.name === bird.name && el.scientific !== "")
        .length === 0
    )
      state.birds.push(createBirdObject(bird));
  });

  // console.log(state.birds);
  // Calculate shortname for sorting
  calculateShortNames();

  // sort alphabetically
  sortBirdsArray();

  // add to local storage
  persistBirds();
};

const calculateShortNames = function () {
  state.birds.forEach(
    (bird) =>
      (bird.shortName = bird.name
        .split(" ")
        .filter((word) => !ALPHABETISE_IGNORE.some((el) => el == word))
        .join(" "))
  );
};

// Remove bird if not in config list (amendments/updates)
const removeBird = function () {
  state.birds.forEach((bird, i) => {
    if (!COMMON_BIRDS.some((el) => el.name === bird.name)) {
      // console.log(bird.name);
      state.birds.splice(i, 1);
    }
  });
};

export const sortBirdsArray = function () {
  state.birds.sort(sortBirds);
};

// Sort bird objects
const sortBirds = function compare(a, b) {
  if (a.shortName < b.shortName) {
    return -1;
  }
  if (a.shortName > b.shortName) {
    return 1;
  }
  return 0;
};

// Takes an array of Bird names and creates an object for each in the state
export const createBirdObjectQuery = function (data) {
  data.forEach(function (bird) {
    if (state.birds.filter((el) => el.name === bird.name).length === 0)
      // console.log(bird);
      state.birds.push(createBirdObject(bird));
  });

  // Calculate shortname for sorting
  calculateShortNames();

  // sort alphabetically
  sortBirdsArray();

  // console.log(state.birds);
  // add to local storage
  persistBirds();
};

// Set active bird
export const setActiveBirdId = function (id) {
  state.activeBirdId = id;
};

// get active recording
export const getActiveRecording = function () {
  const bird = getBird(state.activeBirdId)[0];

  if (bird) {
    const types = state.availableAudioTypes;
    const typeIndex = state.activeAudioTypeIndex;
    const type = types[typeIndex];
    const track = state.activeAudioTrackIndex;

    const recordingId = state.loadedRecordings[type][track].id;
    const recording = bird.recordings[type].filter(
      (rec) => rec.id === recordingId
    )[0];

    return recording;
  }
};

const getTotalRecordings = function () {
  const recordings = getBird(state.activeBirdId)[0].recordings;
  const totalRecordings =
    recordings.call.length + recordings.alarm.length + recordings.song.length;
  return totalRecordings;
};

// Like active Track
export const toggleLikeTrack = function () {
  const recording = getActiveRecording();

  // console.log(recording);

  if (!recording) return;
  if (recording.liked === true) {
    recording.liked = false;
    const index = getLikedIndex(recording.id);
    state.likedTracks.splice(index, 1);
  } else if (recording.liked === false) {
    recording.liked = true;
    state.likedTracks.push(recording.id);
  }

  // console.log(state.likedTracks);
  persistLikedTracks();
  persistBirds();

  return recording.liked;
};

// Get liked track index from like tracks array
const getLikedIndex = function (id) {
  return state.likedTracks.indexOf(id);
};

//////////////////////////
// State Audio Selectors
//
// Reset state audio
export const resetStateAudioSelectors = function () {
  state.activeAudioTypeIndex = 0;
  state.activeAudioTrackIndex = 0;
  state.selectAudioTypeIndex = 0;
  state.selectAudioTrackIndex = 0;
  setAvailableAudioTypes();
  setAvailableAudioTracks();
};

// Reset state track selector
export const resetStateAudioTrackSelector = function () {
  state.selectAudioTrackIndex = 0;
};

// Set select Audio Type Index
export const setSelectAudioTypeIndex = function (i) {
  state.selectAudioTypeIndex = i;
  // console.log(state.selectAudioTypeIndex);
  setAvailableAudioTracks();
};

// Set select Audio Type Index
export const setSelectAudioTrackIndex = function (i) {
  state.selectAudioTrackIndex = i;
};

export const setAvailableAudioTypes = function () {
  // set available types in state
  state.availableAudioTypes = [];
  // const bird = getBird(state.activeBirdId)[0];
  // if (bird.recordings.call.length > 0) state.availableAudioTypes.push("call");
  // if (bird.recordings.song.length > 0) state.availableAudioTypes.push("song");
  // if (bird.recordings.alarm.length > 0) state.availableAudioTypes.push("alarm");
  if (state.loadedRecordings.call.length > 0)
    state.availableAudioTypes.push("call");
  if (state.loadedRecordings.song.length > 0)
    state.availableAudioTypes.push("song");
  if (state.loadedRecordings.alarm.length > 0)
    state.availableAudioTypes.push("alarm");
};

export const setAvailableAudioTracks = function () {
  // console.log(getBird(state.activeBirdId)[0]);
  const typeIndex = state.selectAudioTypeIndex;
  const type = state.availableAudioTypes[typeIndex];
  // const tracks = getBird(state.activeBirdId)[0].recordings[type].length;
  const tracks = state.loadedRecordings[type].length;
  state.availableAudioTracks = tracks;
};

////////////////////////////////////////////////////////////
// Get Data from State
////////////////////////////////////////////////////////////

// get bird object from id param
export const getBird = function (id) {
  return state.birds.filter((bird) => bird.id === id);
};

export const getBirdSearch = function (id) {
  return COMMON_BIRDS.filter((bird) => bird.id === id);
};

/////////////////////////////////////////////////////////////
// Search
/////////////////////////////////////////////////////////////

export const searchResults = function (searchQuery) {
  if (!searchQuery) {
    return;
  }

  let results = [];
  // formats the search query into bird id
  const input = searchQuery.toLowerCase().replaceAll(" ", "_");
  const len = input.length;

  // console.log(input);
  // filter results that include the input string id
  if (len >= 3) results = COMMON_BIRDS.filter((el) => el.id.includes(input));

  if (len < 3) {
    COMMON_BIRDS.forEach((el) => {
      if (el.id.split("_").some((word) => word.slice(0, len) === input))
        results.push(el);
    });

    // console.log(results);
  }

  return results;
};

//////////////////////////////////////////////////////////////////////////
// IMAGES - WIKIPEDIA API IMAGES
//////////////////////////////////////////////////////////////////////////

export const callImagesAPI = async function () {
  const selection = state.birds.filter((bird) =>
    state.search.results.map((el) => el.name).includes(bird.name)
  );
  const birdsNoImage = selection.filter((el) => el.image.src === "");
  // Return if all bird objects have an image source
  if (birdsNoImage.length === 0) return;

  const len = birdsNoImage.length;
  const selectionArray = [];

  if (len > 50) {
    // create new arrays split into 50 queries per call
    const splits = len % 50 > 0 ? Math.trunc(len / 50) + 1 : len / 50;

    for (let i = 0; i < splits; i++) {
      selectionArray.push(selection.slice(i * 50, i * 50 + 50));
    }
  }

  if (len <= 50) {
    selectionArray.push(birdsNoImage);
  }

  // console.log(selectionArray);
  // call API with selection Array
  // selectionArray.forEach((query) => loadImages(query));

  const arrayLen = selectionArray.length;
  // console.log(len);
  // console.log(arrayLen);

  for (let i = 0; i < arrayLen; i++) {
    await loadImages(selectionArray[i]);
  }

  // console.log("finishing call");
};

const loadImages = async function (query) {
  try {
    let redirects;

    const birds = query.map((el) => el.name).join("|");

    // Call API
    const res = await fetch(`${WIKI_API}${birds}`);
    const data = await res.json();

    // console.log(data);

    if (data.query.redirects) redirects = data.query.redirects;
    const pages = data.query.pages;
    const pageKeys = Object.keys(pages);

    // console.log(data);
    // console.log(redirects);
    // console.log(pages);
    // console.log(pageKeys);

    // console.log(pages);

    // Add the corrected titles to state birds object (redirects)
    if (redirects) {
      redirects.forEach(function (data) {
        const from = data.from;
        const to = data.to;
        const birdIndex = state.birds.findIndex((el) => el.name === from);
        state.birds[birdIndex].image.title = to;
      });
    }

    // add titles to state birds object (non-redirects)
    pageKeys.forEach(function (pageKey) {
      const title = pages[pageKey].title;
      const birdIndex = state.birds.findIndex(
        (el) => el.name.toLowerCase() === title.toLowerCase()
      );

      if (birdIndex >= 0) state.birds[birdIndex].image.title = title;
    });

    // Add remaining data to images objects
    pageKeys.forEach(function (pageKey) {
      // console.log(pageKey);
      const title = pages[pageKey].title;
      const pageid = pages[pageKey].pageid;
      const original = pages[pageKey].original;
      const src = original ? pages[pageKey].original.source : "";
      const thumb = pages[pageKey].thumbnail;
      const thumbSrc = thumb ? pages[pageKey].thumbnail.source : "";
      const filename = src ? src.slice(`${src.lastIndexOf("/") + 1}`) : "";

      const birdIndex = state.birds.findIndex((el) => el.image.title === title);
      const birdObject = state.birds[birdIndex];

      // only add data to object if the object exists and there is an image source
      if (birdIndex >= 0 && src) {
        birdObject.image.pageid = pageid;
        birdObject.image.src = src;
        birdObject.image.thumb = thumbSrc;
        birdObject.image.filename = filename;
      }

      // console.log(birdObject);

      // update local storage
      persistBirds();
    });
  } catch (err) {
    console.error(err);
  }
};

//////////////////////////////////////////////////////////////////////////
// IMAGES - WIKIPEDIA API - META DATA
//////////////////////////////////////////////////////////////////////////

//Load images metadata
export const loadImageMeta = async function (id) {
  try {
    // Get bird image object for birdID param
    const birdImage = getBird(id)[0].image;

    // if attribution data then return
    if (birdImage.attribution.attribution) return;

    // filename
    const filename = birdImage.filename;

    // Call API
    const link = `${WIKI_API_META}${filename}&format=json`;
    const res = await fetch(link);
    const data = await res.json();

    // Response object path to meta data
    // data.query.pages[pageid or -1].imageinfo[0].extmetadata
    const pageid = Object.keys(data.query.pages)[0];
    const metadata = data.query.pages[`${pageid}`].imageinfo[0].extmetadata;

    // populate the attribution object for the bird image object
    birdImage.attribution = createAttributionObject(metadata);
  } catch (err) {
    console.error(err);
  }
};

//////////////////////////////////////////////////////////////////////////
// AUDIO - Xeno Canto API
//////////////////////////////////////////////////////////////////////////

// Get xeno region
const getXenoRegion = function () {
  let xenoRegion = "";
  const region = state.search.region;
  // console.log(region);
  if (region !== "") {
    xenoRegion = XENO_REGIONS.filter((reg) => reg.region === region)[0]
      .xenoRegion;
  }

  // console.log(xenoRegion);
  return xenoRegion;
};

// Load audio from xeno canto API
export const loadAudioAPI = async function (birdId) {
  try {
    let data;
    // return if api timeout has not finished

    if (state.xenoApiDisabled === true || state.xenoLoading === true) {
      return;
    }

    let country, region, countryParam;
    let countryTag = "";
    let regionTag = "";
    const page = state.activeMenu;

    country = state.search.country;
    countryParam = country;
    region = state.search.region;

    if (page === "favourites") {
      country = "Worldwide";
      region = "Worldwide";
    }

    let searchQuery = country ? country : region;

    if (region === "Worldwide") searchQuery = "Worldwide";
    if (country === "Various") searchQuery = region;

    state.search.loadedCountry = country;
    state.search.loadedRegion = state.search.region;

    // return if recordings already loaded
    const birdIndex = state.birds.findIndex((el) => el.id === birdId);
    const bird = state.birds[birdIndex];

    if (!bird.recordings.loadedAreas) bird.recordings.loadedAreas = [];
    if (bird.recordings.loadedAreas.includes(searchQuery)) {
      setLoadedAudioTracks();
      return;
    }
    bird.recordings.loadedAreas.push(searchQuery);

    // console.log(bird.recordings.loadedAreas);
    // if (bird.recordings.loaded && bird.recordings.loadedArea === searchQuery)
    //   return;

    // Set API query params
    if (country === "Various" || country === "Worldwide") {
      countryParam = "";
    }

    // console.log(region);

    if (region !== "") {
      region = getXenoRegion();
    }

    // bird.recordings = {
    //   loaded: false,
    //   loadedArea: "",
    //   call: [],
    //   song: [],
    //   alarm: [],
    // };

    bird.recordings.loadedCountry = "";
    bird.recordings.loadedRegion = "";

    if (country !== "" && country !== "Worldwide" && country !== "Various") {
      bird.recordings.loadedCountry = country;
      countryTag = `+cnt:${countryParam}`;
    }
    if (region !== "") {
      bird.recordings.loadedCountry = state.search.region;
      regionTag = `+area:${region}`;
    }

    ///////////////////////////////////////////////////////////////////////
    // Call API. If responses return no results, reduce criteria and retry

    state.xenoLoading = true;
    let totalResults = 0;

    // Attempt 1
    data = await callXenoAPI(
      `${API_URL}?query=${birdId}+q:A${countryTag}${regionTag}+len:10-60`
    );

    processXenoData(data);
    totalResults = getTotalRecordings();

    // Attempt 2
    if (totalResults < 4) {
      data = await callXenoAPI(
        `${API_URL}?query=${birdId}+q:A${regionTag}+len:10-60`
      );
      processXenoData(data);
    }

    // Attempt 3
    totalResults = getTotalRecordings();
    if (totalResults < 4) {
      data = await callXenoAPI(`${API_URL}?query=${birdId}+q:A+len:10-60`);
      processXenoData(data);
    }

    // Attempt 4
    totalResults = getTotalRecordings();
    if (data.numRecordings === "0") {
      data = await callXenoAPI(`${API_URL}?query=${birdId}+len:10-60`);
      processXenoData(data);
    }

    // mark bird object as loaded
    bird.recordings.loaded = true;

    // set loaded audio in state
    setLoadedAudioTracks();

    // set available types in state
    setAvailableAudioTypes();

    // update local storage
    persistBirds();

    // clear state loading
    state.xenoLoading = false;

    return;
  } catch (err) {
    state.xenoLoading = false;
    throw err;
  }
};

const processXenoData = function (data) {
  const bird = getBird(state.activeBirdId)[0];
  // filter data into separate call types
  let calls, songs, alarm;
  calls = filterAudioResults(data, "call", false);
  songs = filterAudioResults(data, "song");
  alarm = filterAudioResults(data, "alarm", true);

  const concat = calls.concat(songs).concat(alarm);
  const numberResults = concat.length;

  // If no results from filtering, reduce filter criteria to include recordings with other species
  if (calls == "" && songs == "" && alarm == "") {
    calls = filterAudioResults(data, "call", false, false);
    songs = filterAudioResults(data, "song", false, false);
    alarm = filterAudioResults(data, "alarm", true, false);
  }

  // Create recording object for each result and add to the state bird object recordings array
  calls.forEach((call) => {
    const exists = bird.recordings.call.some((rec) => rec.id === call.id);
    if (!exists) bird.recordings.call.push(createRecordingObject(call));
  });
  songs.forEach((song) => {
    const exists = bird.recordings.song.some((rec) => rec.id === song.id);
    if (!exists) bird.recordings.song.push(createRecordingObject(song));
  });
  alarm.forEach((alarm) => {
    const exists = bird.recordings.alarm.some((rec) => rec.id === alarm.id);
    if (!exists) bird.recordings.alarm.push(createRecordingObject(alarm));
  });

  return numberResults;
};

// call xeno API
const callXenoAPI = async function (url) {
  const res = await Promise.all([getJSON(url), apiTimeout()]);
  const data = await res[0].json();
  return data;
};

// filter API results - Xeno Canto
const filterAudioResults = function (
  data,
  type,
  includes = false,
  filterSpecies = true
) {
  let filteredType;

  if (includes) {
    filteredType = data.recordings.filter((el) => el.type.includes(type));
  }
  if (!includes) {
    filteredType = data.recordings.filter((el) => el.type === type);
  }

  // filter for mp3. iphone does not support wav
  const filteredFileType = filteredType.filter(
    (el) =>
      el["file-name"].slice(el["file-name"].lastIndexOf(".") + 1) === "mp3"
  );

  // filter out recordings with multiple species
  let filteredSpecies = filteredFileType;

  if (filterSpecies === true)
    filteredSpecies = filteredFileType.filter((el) => !el.also.length);

  return filteredSpecies;
};

// API Timeout function - no more than 1 request per second for Xeno Canto
const apiTimeout = function () {
  state.xenoApiDisabled = true;
  return new Promise((resolve) => {
    setTimeout(function () {
      resolve((state.xenoApiDisabled = false));
    }, API_TIMER_SECONDS);
  });
};

//////////////////////////////////////////////////////////////////////////
// AUDIO - Other functions
//////////////////////////////////////////////////////////////////////////

// Set loaded audio tracks
export const setLoadedAudioTracks = function () {
  // console.log(state.search);
  const page = state.activeMenu;
  const liked = state.settings.likedAudio;
  let country = state.search.loadedCountry;
  if (country === "Various" || country === "Worldwide") {
    country = "";
  }
  let region = state.search.loadedRegion;
  // console.log(region);

  state.loadedRecordings = {
    call: [],
    song: [],
    alarm: [],
  };

  const bird = getBird(state.activeBirdId)[0];
  const recordings = bird.recordings;
  const calls = recordings.call;
  const songs = recordings.song;
  const alarms = recordings.alarm;
  let loadedCalls = state.loadedRecordings.call;
  let loadedSongs = state.loadedRecordings.song;
  let loadedAlarms = state.loadedRecordings.alarm;

  if (liked === "on") {
    // console.log("liked audio only");
    const filterLikedCalls = calls.filter((rec) => rec.liked === true);
    const filterLikedSongs = songs.filter((rec) => rec.liked === true);
    const filterLikedAlarms = alarms.filter((rec) => rec.liked === true);

    if (filterLikedCalls.length > 0)
      state.loadedRecordings.call = filterLikedCalls;
    if (filterLikedSongs.length > 0)
      state.loadedRecordings.song = filterLikedSongs;
    if (filterLikedAlarms.length > 0)
      state.loadedRecordings.alarm = filterLikedAlarms;

    // console.log(state.loadedRecordings);

    return;
  }

  if (region === "Worldwide" || page === "favourites") {
    state.loadedRecordings.call = calls;
    state.loadedRecordings.song = songs;
    state.loadedRecordings.alarm = alarms;
    // console.log(state.loadedRecordings);
    return;
  }

  if (country !== "") {
    const filterCountryCalls = calls.filter((rec) => rec.country === country);
    const filterCountrySongs = songs.filter((rec) => rec.country === country);
    const filterCountryAlarms = alarms.filter((rec) => rec.country === country);

    state.loadedRecordings.call = filterCountryCalls;
    state.loadedRecordings.song = filterCountrySongs;
    state.loadedRecordings.alarm = filterCountryAlarms;

    region = getRegion(country);
  }

  loadedCalls = state.loadedRecordings.call;
  loadedSongs = state.loadedRecordings.song;
  loadedAlarms = state.loadedRecordings.alarm;
  const totalLoaded =
    loadedCalls.length + loadedSongs.length + loadedAlarms.length;

  if (region !== "" || totalLoaded < 5) {
    // console.log(region);
    const filterRegionCalls = calls.filter((rec) => rec.area === region);
    const filterRegionSongs = songs.filter((rec) => rec.area === region);
    const filterRegionAlarms = alarms.filter((rec) => rec.area === region);

    filterRegionCalls.forEach((call) => {
      if (loadedCalls.some((rec) => rec.id === call.id) === true) {
        return;
      } else {
        loadedCalls.push(call);
      }
    });

    filterRegionSongs.forEach((song) => {
      if (loadedSongs.some((rec) => rec.id === song.id) === true) {
        return;
      } else {
        loadedSongs.push(song);
      }
    });

    filterRegionAlarms.forEach((alarm) => {
      if (loadedAlarms.some((rec) => rec.id === alarm.id) === true) {
        return;
      } else {
        loadedAlarms.push(alarm);
      }
    });
  }

  // console.log(state.loadedRecordings);
};

// Get the first available audio from the select Bird Object
export const getFirstAudio = function (birdId) {
  const bird = getBird(birdId);
  // const recordings = bird[0].recordings;
  const recordings = state.loadedRecordings;
  if (bird) {
    if (recordings.call.length > 0) return recordings.call[0];
    if (recordings.song.length > 0) return recordings.song[0];
    if (recordings.alarm.length > 0) return recordings.alarm[0];
  }
  return;
};

// Return the audio object for the selected audio
export const getSelectedAudio = function () {
  if (!state.activeBirdId) return;
  const type = state.availableAudioTypes[state.selectAudioTypeIndex];
  const track = state.selectAudioTrackIndex;
  const recordingId = state.loadedRecordings[type][track].id;
  const bird = getBird(state.activeBirdId);
  const recording = bird[0].recordings[type].filter(
    (rec) => rec.id === recordingId
  )[0];
  if (recording) return recording;
  return;
};

// Update State - Active audio track
export const updateActiveAudio = function () {
  state.activeAudioTrackIndex = state.selectAudioTrackIndex;
  state.activeAudioTypeIndex = state.selectAudioTypeIndex;
};
//

////////////////////////////////////////////////////////////////////
// Favourites
////////////////////////////////////////////////////////////////////

// // Toggle favourite in state bird object
// export const toggleFavourite = function (id) {
// Get bird object
// const birdObject = getBird(id)[0];

// if (!birdObject) return;
// // toggle favourite value
// birdObject.favourite === true
//   ? (birdObject.favourite = false)
//   : (birdObject.favourite = true);

//   // update local storage
//   persistBirds();
// };

// Toggle favourite in state bird object
export const toggleFavourite = function (id) {
  const bird = getBird(id)[0];
  if (state.favourites.includes(id)) {
    state.favourites.splice(state.favourites.indexOf(id), 1);

    bird.favourite = false;
  } else {
    state.favourites.push(id);
    bird.favourite = true;
  }

  persistBirds();
  persistFavourites();
};

export const setFavouriteBirdObjects = function () {
  state.favourites.forEach((fav) => {
    const bird = getBird(fav)[0];
    if (bird) bird.favourite = true;
  });
};

// Populate State - Favourites Array
export const populateFavouritesArray = function () {
  state.favourites = [];
  state.birds.forEach((bird) => {
    if (bird.favourite) state.favourites.push(bird);
  });
};

////////////////////////////////////////////////////////////////////////
// Local Storage
////////////////////////////////////////////////////////////////////////

// update local storage
export const persistBirds = function () {
  localStorage.setItem("birds", JSON.stringify(state.birds));
};

export const persistFavourites = function () {
  localStorage.setItem("favourites", JSON.stringify(state.favourites));
};

export const persistLikedTracks = function () {
  localStorage.setItem("likedTracks", JSON.stringify(state.likedTracks));
};

// update local storage
export const persistSettings = function () {
  localStorage.setItem("settings", JSON.stringify(state.settings));
};

export const persistVersion = function () {
  localStorage.setItem("version", JSON.stringify(state.version));
};

////////////////////////////////////////////////////////////////////////
// Settings
////////////////////////////////////////////////////////////////////////

// settings - update state colours
export const setThemeState = function (colour, contrast, brightness) {
  state.settings.themeRangeColour = colour;
  state.settings.themeRangeContrast = contrast;
  state.settings.themeRangeBrightness = brightness;
  persistSettings();
};

//  settings - update state dark mode
export const toggleSettingLikedAudio = function () {
  const value = state.settings.likedAudio;
  // console.log(value);
  if (value === "off") state.settings.likedAudio = "on";
  if (value === "on" || !value) state.settings.likedAudio = "off";
  persistSettings();

  // console.log(state.settings.likedAudio);
};

//  settings - update state dark mode
export const toggleSettingDarkMode = function () {
  const value = state.settings.settingDarkMode;
  // console.log(value);
  if (value === "off") state.settings.settingDarkMode = "on";
  if (value === "on") state.settings.settingDarkMode = "off";
  persistSettings();
};

//  settings - update state disable images
export const toggleSettingDisableImages = function () {
  const value = state.settings.settingDisableImages;
  if (value === "off") state.settings.settingDisableImages = "on";
  if (value === "on") state.settings.settingDisableImages = "off";
  persistSettings();
};

// settings - update state accessible colours
// export const toggleSettingAccessibilityColours = function () {
//   const value = state.settings.settingAccessibilityColours;
//   if (value === "off") state.settings.settingAccessibilityColours = "on";
//   if (value === "on") state.settings.settingAccessibilityColours = "off";
//   persistSettings();
// };

////////////////////////////////////////////////////////////////////////
// Clear storage
////////////////////////////////////////////////////////////////////////

// const clearStorage = function () {
//   localStorage.removeItem("birds");
//   localStorage.removeItem("version");
// };
// clearStorage();

////////////////////////////////////////////////////////////////////////
// INIT
////////////////////////////////////////////////////////////////////////

// update settings from storage (allows for updates to state form previous versions)
//
const storageSettings = function (storage) {
  const storedSettings = JSON.parse(storage);
  const storageKeys = Object.keys(storedSettings);
  const stateKeys = Object.keys(state.settings);

  storageKeys.forEach((key) => {
    if (stateKeys.includes(key)) state.settings[key] = storedSettings[key];
  });
};

// Update the state from local storage
const init = function () {
  const favourites = localStorage.getItem("favourites");
  const likedTracks = localStorage.getItem("likedTracks");
  const settings = localStorage.getItem("settings");
  const version = localStorage.getItem("version");
  if (settings) storageSettings(settings);
  if (favourites) state.favourites = JSON.parse(favourites);
  if (likedTracks) state.likedTracks = JSON.parse(likedTracks);
  if (version) state.version = JSON.parse(version);

  if (state.version === VERSION) {
    const storageBirds = localStorage.getItem("birds");
    if (storageBirds) state.birds = JSON.parse(storageBirds);
  }

  // update version
  if (state.version !== VERSION) {
    state.version = VERSION;
    persistVersion();
  }
};
init();
