import View from "./View";
import logoBlack from "url:../../images/logo.png";
import logoWhite from "url:../../images/logo-off-white.png";

class Settings extends View {
  _parentElement = document.querySelector(".settings__container");
  title = document.querySelector(".themes__title__colour");
  sliderColour = document.getElementById("themeColour");
  sliderContrast = document.getElementById("themeContrast");
  sliderBrightness = document.getElementById("themeBrightness");
  themeBtns = document.querySelectorAll(".btn__season");

  //
  //
  switchLikedAudio = document.getElementById("setting_liked_audio");
  switchDisableImages = document.getElementById("setting_disable_images");
  switchDarkMode = document.getElementById("setting_dark-mode");
  logo = document.querySelector(".logo");

  addHandlerSliderColour(handler) {
    this.sliderColour.addEventListener("input", handler);
  }

  addHandlerSliderContrast(handler) {
    this.sliderContrast.addEventListener("input", handler);
  }

  addHandlerSliderBrightness(handler) {
    this.sliderBrightness.addEventListener("input", handler);
  }

  addHandlerSettingLikedAudio(handler) {
    this.switchLikedAudio.addEventListener("input", handler);
  }

  addHandlerSettingDarkMode(handler) {
    this.switchDarkMode.addEventListener("input", handler);
  }

  addHandlerSettingDisableImages(handler) {
    this.switchDisableImages.addEventListener("input", handler);
  }

  addHandlerSettingAccessibilityColours(handler) {
    this.switchAccessibilityColours.addEventListener("input", handler);
  }

  addHandlerThemeBtns(handler) {
    this.themeBtns.forEach((btn) =>
      btn.addEventListener("click", function (e) {
        const target = e.target.closest(".btn__season");
        const theme = target.dataset.season;
        handler(theme);
      })
    );
  }

  setThemeBtnBackgrounds(theme) {
    this.themeBtns.forEach((btn) => {
      const svg = btn.querySelector(".svg__container__themes");
      if (btn.dataset.season === theme) {
        svg.style.fill = "var(--colour-highlight)";
      } else {
        svg.style.fill = "";
      }
    });
  }

  // setAccessibilityColours(val) {
  //   // console.log(`toggle - ${val}`)
  //   if (val === "off") {
  //     this.switchAccessibilityColours.value = "off";
  //     this.sliderColour.disabled = false;
  //     this.sliderContrast.disabled = false;
  //     this.sliderBrightness.disabled = false;
  //     this.setColourValue();
  //   }
  //   if (val === "on") {
  //     this.switchAccessibilityColours.value = "on";
  //     this.sliderColour.disabled = true;
  //     this.sliderContrast.disabled = true;
  //     this.sliderBrightness.disabled = true;
  //     this.setAccessibleColours();
  //   }
  // }

  // setAccessibleColours() {
  //   const highlight = `hsl(0, 0%, 100%)`;
  //   const bird = `hsl(0, 0%, 60%)`;
  //   const background = `hsl(0, 0%, 85%)`;
  //   this._setColourProperties(background, highlight, bird);
  // }

  setDisableImagesValue(val) {
    this.switchDisableImages.value = val;
  }

  getColourValue() {
    return this.sliderColour.value;
  }

  getContrastValue() {
    return this.sliderContrast.value;
  }

  getBrightnessValue() {
    return this.sliderBrightness.value;
  }

  setTheme(theme) {
    this._setColourProperties(
      theme.background,
      theme.highlight,
      theme.bird,
      theme.title
    );
  }

  _setColourProperties(background = "", highlight = "", bird = "", title = "") {
    if (background)
      document.documentElement.style.setProperty(
        "--colour-background",
        background
      );
    if (highlight)
      document.documentElement.style.setProperty(
        "--colour-highlight",
        highlight
      );
    if (bird) document.documentElement.style.setProperty("--colour-bird", bird);
    if (title)
      document.documentElement.style.setProperty(
        "--colour-background-title",
        title
      );
  }

  darkMode(state = "") {
    let dark;

    dark = this.switchDarkMode.checked;
    if (state === "off") {
      dark = false;
      this.switchDarkMode.checked = false;
    }

    let background2,
      fontColor,
      border,
      borderColour,
      bodyColor,
      disableSliders,
      svgFill,
      bgSearchResults,
      logoSrc;

    if (dark) {
      // background2 = "#131313";
      background2 = "rgb(25, 25, 25)";
      fontColor = "#FFFFFF";
      border = "none";
      borderColour = "#181818";
      bodyColor = "#FFFFFF";
      disableSliders = true;
      svgFill = "white";
      bgSearchResults = "#131313";
      logoSrc = logoWhite;
    }

    if (!dark) {
      background2 = "#FFFFFF";
      fontColor = "black";
      border = "solid";
      borderColour = "black";
      bodyColor = "black";
      disableSliders = false;
      svgFill = "black";
      bgSearchResults = "whitesmoke";
      logoSrc = logoBlack;
    }

    this.setColourValue();
    document.body.style.setProperty("color", bodyColor);
    document.documentElement.style.setProperty(
      "--colour-background2",
      background2
    );
    document.documentElement.style.setProperty(
      "--colour-background-search-results",
      bgSearchResults
    );
    // document.documentElement.style.setProperty("--colour-background-modal", background2);
    document.documentElement.style.setProperty("--font-color", fontColor);
    document.documentElement.style.setProperty("--bird-border-style", border);
    document.documentElement.style.setProperty(
      "--colour-bird-border",
      borderColour
    );
    document.querySelectorAll(".svg-icon-amend-fill").forEach((icon) => {
      // console.log(icon)
      icon.style.fill = svgFill;
    });

    this.logo.src = logoSrc;

    this.sliderContrast.disabled = disableSliders;
    this.sliderBrightness.disabled = disableSliders;
  }

  setColourValue() {
    const colour = this.getColourValue();

    if (this.switchDarkMode.checked) {
      const highlight = `hsl(${colour}, 100%, 35%)`;
      const title = `hsla(${colour}, 100%, 50%, 0.25)`;
      this._setColourProperties("#060606", highlight, highlight, title);
      return;
    }
    const contrast = this.getContrastValue();
    const brightness = this.getBrightnessValue();
    const bird = `hsl(${colour}, 90%, 60%)`;
    const highlight = `hsl(${colour}, 100%, 50%)`;
    const background = `hsl(${colour}, ${contrast}%, ${brightness}%)`;
    const title = `hsla(${colour}, 100%, 50%, 0.25)`;
    this._setColourProperties(background, highlight, bird, title);
  }

  storageSettings(data) {
    // set colours
    // console.log(data)
    const colour = data.themeRangeColour;
    const contrast = data.themeRangeContrast;
    const brightness = data.themeRangeBrightness;
    const highlight = `hsl(${colour}, 100%, 50%)`;
    const bird = `hsl(${colour}, 90%, 60%)`;
    const background = `hsl(${colour}, ${contrast}%, ${brightness}%)`;
    const title = `hsla(${colour}, 100%, 50%, 0.25)`;
    this._setColourProperties(background, highlight, bird, title);
    this.sliderColour.value = colour;
    this.sliderContrast.value = contrast;
    this.sliderBrightness.value = brightness;

    // set disabled images
    const disabledImages = data.settingDisableImages;
    if (disabledImages === "on") {
      this.switchDisableImages.checked = true;
    }

    const likedAudio = data.likedAudio;
    if (likedAudio === "on") {
      this.switchLikedAudio.checked = true;
    }
    // const accessibilityColours = data.settingAccessibilityColours;
    // // console.log(accessibilityColours)
    // if (accessibilityColours === "on") {
    //   this.switchAccessibilityColours.checked = true;
    //   this.setAccessibilityColours(accessibilityColours);
    // }

    const darkMode = data.settingDarkMode;
    if (darkMode === "on") {
      this.switchDarkMode.checked = true;
      this.darkMode();
    }
  }
}

export default new Settings();
