import * as model from "./model.js";
import menuView from "./views/menuView.js";
import birdsView from "./views/birdsView.js";
import searchResultsView from "./views/searchResultsView.js";
import searchView from "./views/searchView.js";
import birdImageView from "./views/birdImageView.js";
import audioView from "./views/audioView.js";
import birdInfoView from "./views/birdInfoView.js";
import audioTrackView from "./views/audioTrackView.js";
import audioTypeView from "./views/audioTypeView.js";
import audioControlsView from "./views/audioControlsView.js";
import settingsView from "./views/settingsView.js";
import contactView from "./views/contactView.js";
import homeView from "./views/homeView.js";
import modalAttributionAudioView from "./views/modalAttributionAudioView.js";
import modalAttributionImageView from "./views/modalAttributionImageView.js";
import audioScrollerView from "./views/audioScrollerView.js";

import "core-js/actual";
import "regenerator-runtime/runtime";
import searchFilterView from "./views/searchFilterView.js";

import { COMMON_BIRDS } from "./common.js";
import { THEMES } from "./config.js";

//////////////////////////////////////////////////////////
// Render the Birds View (helper function)
//////////////////////////////////////////////////////////

const renderBirdView = function (data) {
  // Hide the other sections
  contactView.hide();
  settingsView.hide();
  homeView.hide();

  // Show content
  birdsView.show();

  // if (model.state.activeMenu !== "favourites") {
  birdsView.showFavouritesInstruction();
  // } else {
  //   birdsView.hideFavouritesInstruction();
  // }

  // Render html
  birdsView.render(data);

  // Set background colour for active bird
  birdsView.setActiveBgColour(model.state.activeBirdId);

  // Add event handlers
  birdsView.addHandlerBird(controlButtonBird);

  // Clear search
  searchResultsView.clearSearch();

  // Animate - Expand the bird sections
  birdsView.animateHeight();

  // Add image handler last
  setTimeout(() => {
    birdImageView.addHandlerImageLoad(controlImageLoad);
  }, 300);
};

////////////////////////////////////////////////////////
// LAZY LOADER HANDLER
////////////////////////////////////////////////////////

const controlImageLoad = function (birdId, imageId) {
  const birdObject = model.getBird(birdId);
  birdImageView.renderImage(
    birdObject,
    imageId,
    model.state.settings.settingDisableImages
  );
};

///////////////////////////////////////////////////////
// SEARCH FILTER
///////////////////////////////////////////////////////

const controlSearchFilterBtn = function () {
  // console.log("filter");
  searchFilterView.showFilterList();
};

const controlSearchFilterItem = async function (value, title) {
  // Set filter criteria in state
  model.setSearchFilter(value); // *--persist--*

  // set filter results in state
  model.searchFilter();

  // Update filter flag/text description
  const searchObject = model.state.search;
  if (searchObject.countryCode2)
    searchView.setFilterEmojiFlag(searchObject.countryCode2);

  if (searchObject.regionCode)
    searchView.setFilterEmojiGlobe(searchObject.regionCode);

  // hide the filter list
  searchFilterView.hideFilterList();

  // set filter title
  searchFilterView.setFilterTitle(title);

  // load common birds for the search filter if the page is already common birds
  const id = window.location.hash.slice(1);
  if (id === "common-birds") controlButtonPageCommonBirds();

  // Render loading spinner
  birdsView.hideFavouritesInstruction();
  showCommonBirdsView();
  birdsView._generateSpinner();
};

///////////////////////////////////////////////////////
// SEARCH
///////////////////////////////////////////////////////

// render the search results list
const controlSearchResults = function () {
  // console.log(model.state.search.regionCode);

  // console.log(model.state.search.region);

  if (model.state.search.regionCode === "ALL") {
    searchResultsView.render(COMMON_BIRDS);
    return;
  }

  if (
    model.state.search.country === "Worldwide" ||
    model.state.search.country === "Various"
  ) {
    searchResultsView.render(
      COMMON_BIRDS.filter((el) =>
        el.commonRegionCodes.includes(model.state.search.regionCode)
      )
    );
    return;
  }

  if (model.state.search.country !== "") {
    // console.log(model.state.search.country);
    searchResultsView.render(
      COMMON_BIRDS.filter((el) =>
        el.commonCountryNames.includes(model.state.search.country)
      )
    );
    return;
  }
};

// render birds view from search query
const controlSearchField = async function () {
  birdsView.setSearch();

  // get the search query from the search field
  const query = searchView.getQuery();
  // console.log(query);
  // return if no query
  if (!query) return;

  // return array of search results
  const results = model.searchResults(query);

  // Set search results in state
  model.state.search.results = results;
  // console.log(model.state.results);

  // Create bird objects
  model.createBirdObjectQuery(results);

  // console.log(model.state.birds);

  const resultsObjects = model.state.birds.filter((bird) =>
    results.some((res) => res.name === bird.name)
  );

  // results.map(
  //   (res) => model.state.birds.filter((bird) => bird.name === res.name)[0]
  // );

  // load Wiki API images
  if (model.state.settings.settingDisableImages === "off")
    await model.callImagesAPI();

  // hide search results view
  searchResultsView.hideSearch();

  // render birds View with search results

  // console.log(resultsObjects);
  renderBirdView(resultsObjects);

  // Hide filter title
  searchFilterView.hideFilterTitle();

  // Clear the active menu
  model.updateActiveMenu("");
};

// Show Common Birds View
const showCommonBirdsView = function () {
  contactView.hide();
  settingsView.hide();
  homeView.hide();

  // Show content
  birdsView.show();
};

// render birds view from results view clicked item
const controlSearchItem = async function (id) {
  searchResultsView.hideSearch();
  birdsView.setSearch();
  const birdSearch = model.getBirdSearch(id);
  model.state.search.results = birdSearch;
  model.createBirdObjectQuery(birdSearch);
  // load Wiki API images
  if (model.state.settings.settingDisableImages === "off")
    await model.callImagesAPI();

  const bird = model.getBird(id);
  // console.log(bird);
  renderBirdView(bird);
  // Hide filter title
  searchFilterView.hideFilterTitle();
};

//////////////////////////////////////
// overlay handler - close search
const controlOverlay = function () {
  searchResultsView.hideSearch();
  searchFilterView.hideFilterList();
};

//////////////////////////////////////////////////////////////////
// NAVIGATION MENU
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////
// Nav menu - button handler
const controlButtonMenu = function () {
  // rotate menu icon direction
  menuView.renderRotationBtn(model.state.menu);
  menuView.clearStyleAnimation();
  // toggle MENU open or closed state
  model.toggleMenuState();
  // birdsView.setContainerBottomMargin(audioView.getAudioContainerHeight());
  birdsView.setFooterMargin(audioView.getAudioContainerHeight());
};

//////////////////////////////////////////////
// Home Button
const controlButtonPageHome = function () {
  birdsView.hideFavouritesInstruction();
  birdsView.hide();
  searchFilterView.hideFilterTitle();
  settingsView.hide();
  contactView.hide();
  birdsView.setTitle("Home");
  homeView.show();
  model.updateActiveMenu("home");
};

/////////////////////////////////////////////////////////
// Menu - Common birds View handler
const controlButtonPageCommonBirds = async function () {
  try {
    // console.log("CPCB");
    model.searchFilter();
    // create all bird objects
    model.createAllBirdObjects();

    // console.log("call images api");
    // load Wiki API images
    if (model.state.settings.settingDisableImages === "off")
      await model.callImagesAPI();

    // console.log("finish images api");
    // Update the active menu
    model.updateActiveMenu("common birds");

    // Render birds view
    // console.log(model.getFilteredBirdObjects());
    renderBirdView(model.getFilteredBirdObjects());

    searchFilterView.showFilterTitle();
    //
    birdsView.setTitle("Common Birds");
  } catch (err) {
    console.error(err);
  }
};

//////////////////////////////////////////////
// Menu - Favourites View Handler
const controlButtonPageFavourites = async function () {
  // Hide menu
  // menuView.renderRotation(model.state.menu);
  // controlButtonMenu()
  // console.log("favourites");
  //
  // update state favourites
  // model.populateFavouritesArray();
  model.setFavouriteBirdObjects();

  if (model.state.settings.settingDisableImages === "off")
    await model.callImagesAPI();

  // update the active menu
  model.updateActiveMenu("favourites");

  // render favourites
  birdsView.setTitle("Favourites");
  renderBirdView(
    model.state.birds.filter((bird) => model.state.favourites.includes(bird.id))
  );
  searchFilterView.hideFilterTitle();
  // model.setSearchParamsWorldwide();
};

//////////////////////////////////////////////
// Menu - Settings
const controlButtonPageSettings = function () {
  // hide menu
  // menuView.renderRotation(model.state.menu);

  //
  birdsView.hideFavouritesInstruction();
  homeView.hide();
  searchFilterView.hideFilterTitle();
  birdsView.hide();
  contactView.hide();
  birdsView.setTitle("Settings");
  settingsView.show();
  model.updateActiveMenu("settings");
};

//////////////////////////////////////////////
// Menu - Contact
const controlButtonPageContact = function () {
  // hide menu
  // menuView.renderRotation(model.state.menu);
  //
  birdsView.hideFavouritesInstruction();
  homeView.hide();
  searchFilterView.hideFilterTitle();
  birdsView.hide();
  settingsView.hide();
  birdsView.setTitle("Contact");
  contactView.show();
  model.updateActiveMenu("contact");
};

////////////////////////////////////////////////////
// Nav menu load page
//

const controlPageLoad = async function () {
  //
  const id = window.location.hash.slice(1);
  menuView.animateNavItem(`nav-${id}`);
  //
  setTimeout(() => {
    // console.log("timeout function");
    menuView.renderRotation("open");
    const orientation = menuView.getWindowOrientation();
    if (orientation !== "landscape-primary") model.state.menu = "closed";
  }, 250);
  //

  setTimeout(() => {
    if (id === "home") {
      controlButtonPageHome();
    }

    if (id === "common-birds") {
      controlButtonPageCommonBirds();
    }

    if (id === "favourites") {
      controlButtonPageFavourites();
    }

    if (id === "settings") {
      controlButtonPageSettings();
    }

    if (id === "contact") {
      controlButtonPageContact();
    }

    // scroll to the top of the content container
    birdsView.scrollUp();
  }, 250);
  //
};

/////////////////////////////////////////////////////////////////////////////////////////////
// BIRDS VIEW
/////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////////
// Birdsview - Bird Handler. This handler is assigned to every 'bird' item in the bird view list
// params:
// birdId = bird id,
// type = action type/where clicked (favourite, audio, image)
//
const controlButtonBird = async function (birdId = "", type = "") {
  try {
    /////////////////////////////////////////
    // worm - favourite
    /////////////////////////////////////////

    if (type === "favourite") {
      // toggle worm colour & state favourites
      if (birdId) {
        birdsView.toggleWorm(birdId, model.state.favourites);
        model.toggleFavourite(birdId);
      }

      // If the active menu is favourites, hide the bird from the birds view
      if (birdId && model.state.activeMenu === "favourites") {
        birdsView.hideBird(birdId);
      }
    }

    //////////////////////////////////////
    // image - info modal
    //////////////////////////////////////
    if (type === "image") {
      // Load the image metadata
      if (model.state.settings.settingDisableImages === "off")
        await model.loadImageMeta(birdId);
      const bird = model.getBird(birdId);

      // generate attribution markup
      modalAttributionImageView.render(bird[0]);
      modalAttributionAudioView.clear();

      // set image source
      birdInfoView.setImageSource(
        bird[0].image.src,
        model.state.settings.settingDisableImages
      );

      // show modal
      birdInfoView.showBirdInfo();
    }

    /////////////////////////////////////
    // audio - click to play
    /////////////////////////////////////
    if (type === "audio") {
      // if audio currently loading, return
      if (model.state.xenoLoading) return;
      const bird = model.getBird(birdId);
      if (model.state.activeBirdId === bird.id) return;

      // pause audio
      audioControlsView.pauseAudio();

      // reset audio loaded state param
      model.state.xenoLoaded = false;

      // if there is an active bird then clear the active background colour
      if (model.state.activeBirdId)
        birdsView.resetBgColour(model.state.activeBirdId);

      // set new active bird
      model.setActiveBirdId(birdId);
      birdsView.setActiveBgColour(birdId);

      // hide audio controls
      audioScrollerView.hide();
      audioView.hideControls();
      audioView.hideSoundwave();
      audioView.hideOverlay();
      audioView.likeBtnColour("grey");

      // call xeno canto api
      await model.loadAudioAPI(birdId);

      // show audio controls
      audioView.showControls();

      // get first available audio data from the state
      const audio = model.getFirstAudio(birdId);

      // add audio data to the audio player
      if (audio) {
        model.state.xenoLoaded = true;
        audioControlsView.render(audio);
      }
      if (!audio) {
        audioControlsView.renderError();
        audioView.hideSelectorContainer();
        return;
      }

      // console.log(audio);
      audioScrollerView.render(audio);
      audioScrollerView.show();
      audioScrollerView.setAnimationVariables();

      // console.log(model.getBird(birdId));
      // rest state audio selectors
      model.resetStateAudioSelectors();

      // Render audio selectors
      audioTypeView.render(model.state.availableAudioTypes);
      audioTypeView.setCurrentSlide(0);
      audioTrackView.render(model.state);

      // set audio event handlers
      audioControlsView.addHandlerAudioEnded(controlAudioEnded);
      audioControlsView.addHandlerAudioPlay(controlAudioPlay);

      // like button colour
      controlLikeButtonColour();

      // set container margin
      // birdsView.setContainerBottomMargin(audioView.getAudioContainerHeight());
      birdsView.setFooterMargin(audioView.getAudioContainerHeight());
    }
  } catch (err) {
    throw err;
  }
};

const controlAudioEnded = function () {
  audioView.pauseSoundwave();
};

const controlAudioPlay = function () {
  audioView.playSoundwave();
};

//////////////////////////////////////////////////////////
// BIRD - AUDIO INFO VIEW MODAL
///////////////////////////////////////////////////////////

const controlAudioInfoBtn = async function () {
  // if no active bird or loaded audio, return
  if (!model.state.xenoLoaded) return;
  if (!model.state.activeBirdId) return;

  // load images
  if (model.state.settings.settingDisableImages === "off")
    await model.loadImageMeta(model.state.activeBirdId);

  // get bird object
  const bird = model.getBird(model.state.activeBirdId);

  // generate attribution markup
  modalAttributionImageView.render(bird[0]);
  modalAttributionAudioView.render(model.state);

  // set image source
  birdInfoView.setImageSource(
    bird[0].image.src,
    model.state.settings.settingDisableImages
  );

  // show modal
  birdInfoView.showBirdInfo();
};

//////////////////////////////////////////////////////////
// AUDIO LIKE BUTTON
///////////////////////////////////////////////////////////

const controlAudioLikeBtn = function () {
  const activeBird = model.state.activeBirdId;
  if (!activeBird) return;

  model.toggleLikeTrack();
  controlLikeButtonColour();
};

const controlLikeButtonColour = function () {
  const recording = model.getActiveRecording();
  // console.log(recording);
  const liked = recording.liked;
  // console.log(liked);
  if (liked) audioView.likeBtnColour("red");
  if (!liked) audioView.likeBtnColour("grey");
};
///////////////////////////////////////////////////////////
// AUDIO VIEW
///////////////////////////////////////////////////////////

// Render selected Audio
const controlAudioViewSelectBtn = function () {
  // get selected data

  const audio = model.getSelectedAudio();

  // render in audio controls view
  if (audio) {
    audioScrollerView.hide();
    audioControlsView.render(audio);
    audioScrollerView.render(audio);
    audioScrollerView.show();
    audioScrollerView.setAnimationVariables();
    audioView.hideSelectButton();
    audioView.showSoundwave();
    model.updateActiveAudio();
  }

  controlLikeButtonColour();

  // set audio event handlers
  audioControlsView.addHandlerAudioEnded(controlAudioEnded);
  audioControlsView.addHandlerAudioPlay(controlAudioPlay);
};

// handler for Audio Type
const controlTypeSelector = function (index) {
  model.setSelectAudioTypeIndex(index);
  model.resetStateAudioTrackSelector();
  audioView.hideSoundwave();
  audioView.showSelectButton();
  audioTrackView.update(model.state);
};

// handler for Audio Track
const controlTrackSelector = function (index) {
  model.setSelectAudioTrackIndex(index);
  audioView.hideSoundwave();
  audioView.showSelectButton();
  audioTrackView.update(model.state);
};

//////////////////////////////////////////////////////////////
// WINDOW ORIENTATION CSS
//////////////////////////////////////////////////////////////

// handler for setting the type selector width
const controlOrientation = function () {
  audioTypeView.setContainerWidth(model.state.availableAudioTypes);
  // birdsView.setContainerBottomMargin(audioView.getAudioContainerHeight());
  birdsView.setFooterMargin(audioView.getAudioContainerHeight());

  menuView.orientationSettings();
};

//////////////////////////////////////////////////////////////
// SETTINGS VIEW
//////////////////////////////////////////////////////////////

// Page load - set stored colour settings
const controlThemeState = function () {
  const colour = settingsView.getColourValue();
  const contrast = settingsView.getContrastValue();
  const brightness = settingsView.getBrightnessValue();
  model.setThemeState(colour, contrast, brightness);
};

// Colours handler
const controlThemeColour = function () {
  settingsView.setColourValue();
  controlThemeState();
};
// REFACTOR....................................................................
// liked audio Handler
const controlSettingLikedAudio = function () {
  // set setting state
  model.toggleSettingLikedAudio();
  const birdId = model.state.activeBirdId;
  // if active bird
  if (model.state.activeBirdId !== "" && model.state.xenoLoading === false) {
    // set recording object in the model
    model.setLoadedAudioTracks();
    //
    //
    // re-render tracks
    // get first available audio data from the state
    const audio = model.getFirstAudio(birdId);

    // add audio data to the audio player
    if (audio) audioControlsView.render(audio);
    if (!audio) {
      audioControlsView.renderError();
      audioView.hideSelectorContainer();
      return;
    }

    audioScrollerView.render(audio);
    audioScrollerView.show();
    audioScrollerView.setAnimationVariables();

    // console.log(model.getBird(birdId));
    // rest state audio selectors
    model.resetStateAudioSelectors();

    // Render audio selectors
    audioTypeView.render(model.state.availableAudioTypes);
    audioTypeView.setCurrentSlide(0);
    audioTrackView.render(model.state);
    audioView.showSelectorContainer();
    audioTypeView.setContainerWidth(model.state.availableAudioTypes);

    // set audio event handlers
    audioControlsView.addHandlerAudioEnded(controlAudioEnded);
    audioControlsView.addHandlerAudioPlay(controlAudioPlay);

    // like button colour
    controlLikeButtonColour();

    // set container margin
    // birdsView.setContainerBottomMargin(audioView.getAudioContainerHeight());
    birdsView.setFooterMargin(audioView.getAudioContainerHeight());
  }
};

// dark mode Handler
const controlSettingDarkMode = function () {
  model.toggleSettingDarkMode();
  const value = model.state.settings.settingDarkMode;
  settingsView.darkMode(value);
};

// disable images handler
const controlSettingDisableImages = function () {
  model.toggleSettingDisableImages();
  const value = model.state.settings.settingDisableImages;
  settingsView.setDisableImagesValue(value);
};

const controlSettingTheme = function (theme) {
  // console.log(theme);
  if (model.state.settings.settingDarkMode === "on") {
    model.toggleSettingDarkMode();
    settingsView.darkMode("off");
  }

  settingsView.setThemeBtnBackgrounds(theme);
  settingsView.setTheme(THEMES[theme]);
  model.state.settings.theme = theme;
};
// accessible colours handler
// const controlSettingAccessibilityColours = function () {
//   model.toggleSettingAccessibilityColours();
//   const value = model.state.settings.settingAccessibilityColours;
//   settingsView.setAccessibilityColours(value);
// };

//////////////////////////////////////////////////////////////////
// INIT
//////////////////////////////////////////////////////////////////

const init = function () {
  // model.searchFilter();

  const faves = COMMON_BIRDS.filter((bird) =>
    model.state.favourites.includes(bird.id)
  );
  model.state.search.results = faves;
  model.createAllBirdObjects();

  menuView.addHandlerMenuButton(controlButtonMenu);
  menuView.addHandlerNavigation(controlPageLoad);
  birdsView.addHandlerBird(controlButtonBird);
  searchResultsView.addHandlerSearchResults(controlSearchResults);
  searchResultsView.addHandlerOverlay(controlOverlay);
  searchResultsView.addHandlerSearchItem(controlSearchItem);
  searchView.addHandlerSearch(controlSearchField);
  searchFilterView.addHandlerFilterBtn(controlSearchFilterBtn);
  searchFilterView.addHandlerFilterList(controlSearchFilterItem);
  settingsView.storageSettings(model.state.settings);
  settingsView.addHandlerSliderColour(controlThemeColour);
  settingsView.addHandlerSliderContrast(controlThemeColour);
  settingsView.addHandlerSliderBrightness(controlThemeColour);
  settingsView.addHandlerSettingLikedAudio(controlSettingLikedAudio);
  settingsView.addHandlerSettingDarkMode(controlSettingDarkMode);
  settingsView.addHandlerSettingDisableImages(controlSettingDisableImages);
  settingsView.addHandlerThemeBtns(controlSettingTheme);
  // settingsView.addHandlerSettingAccessibilityColours(
  //   controlSettingAccessibilityColours
  // );
  audioView.addHandlerSelectBtn(controlAudioViewSelectBtn);
  audioView.addHandlerinfoBtn(controlAudioInfoBtn);
  audioView.addHandlerLikeBtn(controlAudioLikeBtn);
  audioTypeView.addHandlerNextType(controlTypeSelector);
  audioTypeView.addHandlerPrevType(controlTypeSelector);
  audioTypeView.addHandlerOrientation(controlOrientation);
  audioTrackView.addHandlerNextTrack(controlTrackSelector);
  audioTrackView.addHandlerPrevTrack(controlTrackSelector);
  audioView.hideSoundwave();
  audioView.likeBtnColour("grey");
  // audioView.showOverlay();

  // orientation checkers
  menuView.orientationSettings();
  birdsView.setFooterMargin(audioView.getAudioContainerHeight());

  // location
  if (model.state.search.countryCode2)
    searchView.setFilterEmojiFlag(model.state.search.countryCode2);

  if (model.state.search.regionCode)
    searchView.setFilterEmojiGlobe(model.state.search.regionCode);

  searchFilterView.setFilterTitle(model.state.search.country);
};
init();
