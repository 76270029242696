import View from "./View";

class searchResultsView extends View {
  _parentElement = document.querySelector(".search-results__list");
  _search = document.querySelector(".search");
  _searchField = document.querySelector(".search__field");
  _searchResults = document.querySelector(".search-results");
  _overlay = document.querySelector(".overlay");

  hideSearch() {
    this._overlay.classList.add("hidden");
    this._searchResults.classList.add("hidden");
  }

  _showSearch() {
    this._overlay.classList.remove("hidden");
    this._searchResults.classList.remove("hidden");
  }

  clearSearch() {
    this._searchField.value = "";
  }

  addHandlerOverlay(handler) {
    this._overlay.addEventListener("click", function (e) {
      handler();
    });
  }

  addHandlerSearchResults(handler) {
    this._searchField.addEventListener("keyup", function (e) {
      handler();
    });
  }

  addHandlerSearchItem(handler) {
    this._parentElement.addEventListener("click", function (e) {
      const item = e.target.closest(".search-results__item");
      // console.log(item);
      if (!item) return;
      handler(item.dataset.id);
    });
  }

  _generateMarkup(data) {
    const input = this._searchField.value.toLowerCase().replaceAll(" ", "_");

    // if not input then hide the seatchlist
    if (!input) {
      this.hideSearch();
      return;
    }

    // unhide the results list and the overlay
    this._showSearch();

    //
    const results = data.filter((el) => el.id.includes(input));

    if (!results) {
      this.hideSearch();
      return;
    }

    if (results) {
      const markup = results
        .map((el) => this._generateMarkupResult(el))
        .join("");
      return markup;
    }
  }

  _generateMarkupResult(data) {
    return `<li class="search-results__item" data-id="${data.id}">${data.name}</li>`;
  }
}

export default new searchResultsView();
